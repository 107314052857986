import {create} from 'zustand'

interface ILoginModalStore {
    isVisibleLoginModal?: boolean
    initRoute?: string
    redirectUrl?: string
    setRedirectUrl: (redirectUrl?: string) => void
    showLoginModal: (redirectUrl?: string) => void
    showLoginModalWithInitRoute: (initRoute: string, redirectUrl?: string) => void
    hideLoginModal: () => void
}

export const useLoginModalStore = create<ILoginModalStore>()((set, get) => ({
    isVisibleLoginModal: false,
    redirectUrl: undefined,
    setRedirectUrl: (redirectUrl?: string) => {
        set(state => ({redirectUrl: redirectUrl}))
    },
    showLoginModal: (redirectUrl?: string) => {
        set(state => ({isVisibleLoginModal: true, redirectUrl: redirectUrl}))
    },
    showLoginModalWithInitRoute: (initRoute: string, redirectUrl?: string) => {
        set(state => ({isVisibleLoginModal: true, initRoute: initRoute, redirectUrl: redirectUrl}))
    },
    hideLoginModal: () => {
        set(state => ({isVisibleLoginModal: false}))
    },
}))
