export const getNumberColorClass = (number: number) => {
    if (number > 0) return 'text-primary_shade dark:text-dark_primary_shade'
    else if (number < 0) return 'text-red_shade dark:text-dark_red_shade'
    else return 'text-gray03 dark:text-dark_gray03'
}

export const safeReturn = (value?: any) => {
    if (!value) return undefined

    if (typeof value === 'string') {
        return value.length > 0 ? value : undefined
    } else {
        return value
    }
}
